import { earlyRequestUserAttributes } from 'frontend-preferences-client/quickFetch';
import { treatmentsPromise } from 'dashboard-ui-components/js/data/laboratoryClient';
const DASHBOARDS_ENDPOINT = `/dashboard/v2`;
const DASHBOARDS_URL = `${DASHBOARDS_ENDPOINT}/dashboard?hydrate=USER_PERMISSION_LEVEL`;
const REPORT_COUNTS_URL = `${DASHBOARDS_ENDPOINT}/reports/report-counts`;
export const quickFetchInstance = window.quickFetch;
const portalId = quickFetchInstance.getPortalId();

// Unlike hub-http, quick-fetch does not have built in URL query parsing
// so the query params must be manually added to the URL
const USER_ATTRIBUTES_KEYS = {
  PREFERRED_HOME: `preferred-home:portal-${portalId}`,
  DB_FILTERS_SUNSET_ALERT_DISMISSED_TIMESTAMP_KEY: 'Reporting:DashboardFiltersSunsetAlertDismissedTimestamp'
};
const ATTRIBUTE_KEYS = Object.values(USER_ATTRIBUTES_KEYS);
const MAX_TIMEOUT = 50000;
const baseConfig = {
  timeout: MAX_TIMEOUT,
  dataType: 'json',
  contentType: 'application/json',
  type: 'GET'
};

// Grab dashboard ID from URL to use for get request for current dashboard data.
const matches = window.location.pathname.match(/view?\/(\d+)/);
const DASHBOARD_ID = matches && matches.length ? matches[1] : null;
const DASHBOARD_URL = `${DASHBOARDS_ENDPOINT}/dashboard/${DASHBOARD_ID}`;
earlyRequestUserAttributes(ATTRIBUTE_KEYS, 'userAttributes');
quickFetchInstance.makeEarlyRequest('dashboards', Object.assign({
  url: quickFetchInstance.getApiUrl(DASHBOARDS_URL)
}, baseConfig));
quickFetchInstance.makeEarlyRequest('reportCounts', Object.assign({
  url: quickFetchInstance.getApiUrl(REPORT_COUNTS_URL)
}, baseConfig));
const HYDRATE_PARAMS = ['FAVORITE', 'PERMISSION_CONFIG', 'USER_PERMISSION_LEVEL', 'WIDGET', 'FILTERS', 'DATA_SOURCES'];
const DEFAULT_FETCH_DASHBOARD_QUERY_PARAMS = `record=true&${HYDRATE_PARAMS.map(param => `hydrate=${param}`).join('&')}`;
if (DASHBOARD_ID) {
  quickFetchInstance.makeEarlyRequest('dashboard', Object.assign({
    url: quickFetchInstance.getApiUrl(`${DASHBOARD_URL}?${DEFAULT_FETCH_DASHBOARD_QUERY_PARAMS}`)
  }, baseConfig));
}
void treatmentsPromise;